import request from '@/schedule/utils/publicRequest.js'

export function getColumnCandidates () {
  return request({
    url: '/lineContent/candidates',
    method: 'GET'
  })
}

export function getSelectedColumns (projectId) {
  return request({
    url: `/lineContent/project/${projectId}`,
    method: 'GET'
  })
}

export function setColumns ({ projectId, items }) {
  return request({
    url: `/lineContent/project/${projectId}`,
    method: 'PUT',
    data: { items }
  })
}
