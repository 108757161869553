import { render, staticRenderFns } from "./CandidateItem.vue?vue&type=template&id=07c2f3f6&scoped=true&"
import script from "./CandidateItem.vue?vue&type=script&lang=js&"
export * from "./CandidateItem.vue?vue&type=script&lang=js&"
import style0 from "./CandidateItem.vue?vue&type=style&index=0&id=07c2f3f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07c2f3f6",
  null
  
)

export default component.exports