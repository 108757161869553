<template>
  <div class="ss-transfer">
    <div class="ss-transfer__content">
      <div class="ss-transfer__content-label">
        {{ leftLabel }}
      </div>
      <div v-if="unselectedItems.length > 0">
        <ul class="ss-transfer__list">
          <candidate-item
            v-for="item in unselectedItems"
            :key="item.key"
            :is-selected="isItemSelected(item.key)"
            :candidate="item"
            @toggle="addItem"
          />
        </ul>
      </div>
      <div
        v-else
        class="is-gray"
      >
        {{ $t('schedule.transfer.noItemIsSelectable') }}
      </div>
    </div>
    <div class="ss-transfer__panel">
      <svg-icon
        icon-class="arrow-left"
        class="arrow-right"
      />
      <svg-icon icon-class="arrow-left" />
    </div>
    <div class="ss-transfer__content">
      <div class="ss-transfer__content-label">
        {{ rightLabel }}
        <span class="draggable-reminding">
          {{ $t('schedule.transfer.pressItemToDrag') }}
        </span>
      </div>
      <div v-if="selectedItems.length > 0">
        <ul class="ss-transfer__list">
          <draggable
            v-model="selectedItems"
            @change="sortItem"
          >
            <candidate-item
              v-for="item in selectedItems"
              :key="item.key"
              :is-selected="isItemSelected(item.key)"
              :candidate="item"
              draggable
              @toggle="removeItem"
            />
          </draggable>
        </ul>
      </div>
      <div
        v-else
        class="is-gray"
      >
        {{ $t('schedule.transfer.noItemIsSelected') }}
      </div>
    </div>
  </div>
</template>

<script>
import CandidateItem from './CandidateItem.vue'
import draggable from 'vuedraggable'

export default {
  name: 'ScheduleTransfer',
  components: {
    CandidateItem,
    draggable
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    candidates: {
      type: Array,
      default: () => []
    },
    leftLabel: {
      type: String,
      default: ''
    },
    rightLabel: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      innerValue: [],
      selectedItems: []
    }
  },
  mounted () {
    this.innerValue = [...this.value]
    this.setSelectedItems()
  },
  computed: {
    unselectedItems () {
      return this.candidates.filter(candidate => this.innerValue.findIndex(item => item === candidate.key) === -1)
    }
  },
  methods: {
    addItem (itemKey) {
      this.innerValue = [itemKey, ...this.innerValue]
      this.setSelectedItems()
      this.$emit('input', this.innerValue)
    },
    removeItem (itemKey) {
      const itemIndex = this.innerValue.findIndex(item => item === itemKey)
      if (itemIndex > -1) {
        this.innerValue.splice(itemIndex, 1)
        this.setSelectedItems()
        this.$emit('input', this.innerValue)
      }
    },
    sortItem () {
      this.innerValue = this.selectedItems.reduce((acc, cur) => acc.concat(cur.key), [])
      this.$emit('input', this.innerValue)
    },
    setSelectedItems () {
      this.selectedItems = this.innerValue.map(item => this.candidates.find(candidate => candidate.key === item))
    },
    isItemSelected (itemKey) {
      return this.innerValue.findIndex(item => item === itemKey) > -1
    }
  }
}
</script>

<style lang="scss" scoped>
.ss-transfer {
  display: flex;
  width: 100%;
  &__content {
    background: rgba(38, 46, 46, 0.95);
    border-radius: 8px;
    flex: 1;
    padding: 16px;
    &-label {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
    }
  }
  &__list {
    height: 520px;
    margin: 0;
    overflow: auto;
    padding: 0;
  }
  &__panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 40px;
    .arrow-right {
      margin-bottom: 12px;
      transform: rotate(180deg);
    }
  }
}
.is-gray {
  color: #aaa;
}
.draggable-reminding {
  color: #aaa;
  font-size: 13px;
}
</style>
