<template>
  <div class="ss-setting">
    <div class="ss-setting__header">
      <h2 class="header__title">
        {{ $t('schedule.setting.resultColumnSetting') }}
      </h2>
      <div class="header__action">
        <schedule-button @click="saveSetting">
          {{ $t('schedule.button.save') }}
        </schedule-button>
      </div>
    </div>
    <div class="ss-setting__body">
      <section class="body__block">
        <div class="block__title">
          {{ $t('schedule.setting.resultColumnSetting') }}
        </div>
        <div class="block__form">
          <spinner v-if="isLoading" />
          <schedule-transfer
            v-else
            v-model="selectedColumns"
            :candidates="columnCandidates"
            :left-label="$t('schedule.transfer.notSelected')"
            :right-label="$t('schedule.transfer.selected')"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ScheduleTransfer from '@/schedule/components/transfer/ScheduleTransfer.vue'
import { getColumnCandidates, getSelectedColumns, setColumns } from '@/schedule/API/LineContent'
import { dashToCamel } from '@/schedule/utils/utils'
import { mapState } from 'vuex'
import { Message } from 'element-ui'

export default {
  name: 'ResultDataColumnSetting',
  components: {
    ScheduleTransfer
  },
  data () {
    return {
      isLoading: true,
      isProcessing: false,
      selectedColumns: [],
      columnCandidates: []
    }
  },
  computed: {
    ...mapState('scheduleSetting', ['scheduleProjectId'])
  },
  created () {
    this.initTransferData()
  },
  methods: {
    initTransferData () {
      Promise.all([
        getColumnCandidates(),
        getSelectedColumns(this.scheduleProjectId)
      ])
        .then(([candidates, { items }]) => {
          this.columnCandidates = candidates.map(item => ({
            key: item,
            label: this.$t(`schedule.simulation.yukiTable.${dashToCamel(item)}`)
          }))
          this.selectedColumns = items || []
        })
        .catch(() => {})
        .finally(() => this.isLoading = false)
    },
    saveSetting () {
      if (this.isProcessing) return

      setColumns({ projectId: this.scheduleProjectId, items: this.selectedColumns })
        .then(() => {
          Message({
            message: this.$t('schedule.successMessage.settingSaved'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
        })
        .catch(() => {})
        .finally(() => this.isProcessing = false)
    }
  }
}
</script>
