<template>
  <div
    class="ss-transfer__item"
    :class="{ 'is-draggable': draggable }"
  >
    <div class="ss-transfer__item-label">
      <svg-icon
        v-if="draggable"
        icon-class="vector"
      />
      {{ candidate.label }}
    </div>
    <schedule-button
      @click="toggle(candidate.key)"
      :type="buttonInfo.type"
    >
      {{ buttonInfo.label }}
    </schedule-button>
  </div>
</template>

<script>
export default {
  name: 'CandidateItem',
  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    candidate: {
      type: Object,
      default: () => {},
      required: true
    },
    draggable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonInfo () {
      return {
        label: this.isSelected ? this.$t('schedule.button.cancel') : this.$t('schedule.button.select'),
        type: this.isSelected ? 'secondary' : 'default'
      }
    }
  },
  methods: {
    toggle (value) {
      this.$emit('toggle', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.ss-transfer__item {
  align-items: center;
  background: #475353;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 16px;

  & + .ss-transfer__item {
    margin-top: 10px;
  }

  &-label {
    align-items: center;
    display: flex;

    .svg-icon {
      color: #ccc;
      margin-right: 8px;
    }
  }

  &.is-draggable {
    cursor: grab;
  }
}
</style>
